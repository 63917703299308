<template>
    <Card class="shadow-lg my-5 p-4">
      <template #content>
        <!-- <Steps :steps="tabs" @component="stepOnPage" /> -->
        <keep-alive>
          <component :is="component" @step="onStep"></component>
        </keep-alive>
      </template>
    </Card>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import Amount from "../modules/invoice/views/amount/Create.vue";
import Steps from "../modules/invoice/components/Steps.vue";
import RecipientDetails from "../modules/invoice/views/recipientDetails/Create.vue";
import PayerDetails from "../modules/invoice/views/payerDetails/Create.vue";
import Confirmation from "../modules/invoice/views/confirmation/Create.vue";

export default {
  components: {
    Card,
    Button,
    Steps,
    Amount,
    RecipientDetails,
    PayerDetails,
    Confirmation,
  },
  data() {
    return {
      tabs: [
        {
          component: "Amount",
          label: this.$t("Invoice amount"),
        },
        {
          component: "RecipientDetails",
          label: this.$t("Recipient details"),
        },
        {
          component: "PayerDetails",
          label: this.$t("Payer details"),
        },
        {
          component: "Confirmation",
          label: this.$t("Signatures and seal"),
        },
      ],
      component: "Amount",
    };
  },
  methods: {
    stepOnPage(step) {
      this.component = step;
    },
    onStep(component) {
      this.component = component
    }
  },
};
</script>
