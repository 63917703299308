<template>
  <Steps :step="4" />
  <div class="mt-5">
    <!-- Для печати PDF -->
    <div ref="content" class="d-none">
      <table id="table-info" class="table table-sm table-bordered border-dark">
        <tbody>
          <tr>
            <td colspan="2" rowspan="2" class="pb-5">{{ service.providerBank }}</td>
            <td>БИК</td>
            <td>{{ service.providerBic }}</td>
          </tr>
          <tr>
            <td rowspan="2">Сч. №</td>
            <td rowspan="2">{{ service.providerCorrAccount }}</td>
          </tr>
          <tr>
            <td colspan="2">Банк получателя</td>
          </tr>
          <tr>
            <td>ИНН {{ service.providerInn }}</td>
            <td>КПП {{ service.providerKpp }}</td>
            <td rowspan="4">Сч. №</td>
            <td rowspan="4">{{ service.providerBeneficiaryAccount }}</td>
          </tr>
          <tr>
            <td colspan="2">{{ service.providerName }}</td>
          </tr>
          <tr>
            <td colspan="2">Получатель</td>
          </tr>
        </tbody>
      </table>
      <!-- <div class="mt-4 table-pdf border border-dark">
        <div class="row">
          <div class="col-6">
            <div class="border border-dark border-top-0 border-start-0 pb-5 p-2 custom-text-dark">
              {{ service.providerBank }}
            </div>
            <div class="p-2 border border-dark border-top-0 border-start-0">Банк получателя</div>
          </div>
          <div class="col-2">
            <div class="p-2 border border-dark border-top-0 border-start-0 custom-text-dark">БИК</div>
            <div class="p-2 pb-5 border border-dark border-top-0 border-start-0 custom-text-dark">Сч. №</div>
          </div>
          <div class="col-4">
            <div class="p-2 border border-dark border-top-0 border-start-0 border-end-0 custom-text-dark">
              {{ service.providerBic }}
            </div>
            <div class="p-2 pb-5 border border-dark border-top-0 border-start-0 border-end-0 custom-text-dark">
              {{ service.providerCorrAccount }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="border border-dark border-top-0 border-start-0 border-bottom-0">
              <div class="row">
                <div class="col-6">
                  <div class="p-2 border border-dark border-top-0 border-start-0 border-bottom-0 custom-text-dark">
                    ИНН {{ service.providerInn }}
                  </div>
                </div>
                <div class="col-6">
                  <div class="p-2 custom-text-dark">КПП {{ service.providerKpp }}</div>
                </div>
              </div>
            </div>
            <div class="pb-5 p-2 border border-dark border-start-0 border-bottom-0 custom-text-dark">
              {{ service.providerName }}
            </div>
            <div class="p-2 border border-dark border-start-0 border-bottom-0 custom-text-dark">Получатель</div>
          </div>
          <div class="col-2 border border-dark border-top-0 border-start-0 border-bottom-0 custom-text-dark">
            <div class="p-2 custom-text-dark">Сч. №</div>
          </div>
          <div class="col-4">
            <div class="p-2 custom-text-dark">{{ service.providerBeneficiaryAccount }}</div>
          </div>
        </div>
      </div> -->
      <table id="main-header" class="w-100 fs-3 my-4">
        <tbody>
          <tr>
            <td>{{ $t('Account No.') }} {{ service.number }} {{ $t('from') }} {{ service.date }}</td>
            <hr class="border-bottom border-3 border-dark" />
          </tr>
        </tbody>
      </table>
      <table id="table-provider" class="table">
        <tbody>
          <tr>
            <td>{{ $t('Provider') }}:</td>
            <td>
              {{ service.providerName }}
              {{ service.providerInn ? $t('INN') + ': ' + service.providerInn + ',' : '' }}
              {{ service.providerKpp ? $t('KPP') + ': ' + service.providerKpp + ',' : '' }}
              {{ service.providerAddress ? $t('address') + ': ' + service.providerAddress + ',' : '' }}
              {{ service.providerPhone ? $t('tel:') + ' ' + service.providerPhone : '' }}
            </td>
          </tr>
          <tr>
            <td>{{ $t('Client') }}:</td>
            <td>
              {{ service.сustomerName }}
              {{ service.customerInn ? $t('INN') + ': ' + service.customerInn + ',' : '' }}
              {{ service.сustomerAddress ? $t('address') + ': ' + service.сustomerAddress + ',' : '' }}
              {{ service.сustomerPhone ? $t('tel:') + ' ' + service.сustomerPhone : '' }}
            </td>
          </tr>
          <tr v-if="service.isBase != 1 && service.base">
            <td>{{ $t('Base') }}:</td>
            <td>
              {{ service.base }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="service.products" class="px-1">
        <table id="table-product" class="table table-bordered border-dark">
          <tbody>
            <tr>
              <th scope="col custom-text-dark">№</th>
              <th scope="col custom-text-dark">{{ $t('Product or service') }}</th>
              <th scope="col custom-text-dark">{{ $t('Qty') }}</th>
              <th scope="col custom-text-dark">{{ $t('U') }}</th>
              <th scope="col custom-text-dark">{{ $t('Price') }}</th>
              <th scope="col custom-text-dark">{{ $t('Sum') }}</th>
            </tr>
            <tr v-for="(product, i) in service.products" :key="i">
              <td class="custom-text-dark">{{ i + 1 }}</td>
              <td class="custom-text-dark">{{ product.name }}</td>
              <td class="custom-text-dark">{{ product.qty }}</td>
              <td class="custom-text-dark">{{ product.unit }}</td>
              <td class="custom-text-dark">{{ product.price }}</td>
              <td class="custom-text-dark">{{ product.sum }}</td>
            </tr>
          </tbody>
        </table>

        <table id="table-total">
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td>
                {{
                  service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1'
                    ? $t('Total, incl. VAT')
                    : $t('Total')
                }}:
              </td>
              <td>{{ Str.money(service.countSum) }} ₽</td>
            </tr>
            <tr v-if="service.isInvoiceVAT">
              <td></td>
              <td></td>
              <td>{{ $t('VAT') }}:</td>
              <td>{{ Str.money(service.countVAT) }} ₽</td>
            </tr>
            <tr>
              <td>
                {{ $t('Total items') }} {{ service.countItems }} {{ $t('for the amount') }}
                {{
                  !service.isInvoiceVAT || (service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1')
                    ? Str.money(service.countSum)
                    : Str.money(service.countSumVat)
                }}
                ₽
              </td>
            </tr>
            <tr>
              <td>{{ !service.textSum ? 'Ноль рублей 00 копеек' : service.textSum }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="service.comment">
        <table id="table-comment">
          <tbody>
            <tr>
              <td>{{ service.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Для вывода -->
    <div>
      <h1 class="fs-3 mb-4">
        {{ $t('Almost done, it remains to double-check the data') }}
      </h1>
      <div class="border rounded p-3">
        <div class="row">
          <p class="col-md-2">{{ $t('Payees bank') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerBank }}</p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('BIC') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerBic }}</p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('Account number') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerCorrAccount }}</p>
        </div>
      </div>
      <div class="border rounded p-3 mt-3">
        <div class="row">
          <p class="col-md-2">{{ $t('Recipient') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerName }}</p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('INN') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerInn }}</p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('Account number') }}</p>
          <p class="col-md-10 fw-bold">
            {{ service.providerBeneficiaryAccount }}
          </p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('KPP') }}</p>
          <p class="col-md-10 fw-bold">{{ service.providerKpp }}</p>
        </div>
      </div>
      <h2 class="fs-3 my-4">
        {{ $t('An invoice for payment №') }} {{ service.number }} {{ $t('from') }} {{ service.date }}
      </h2>
      <div class="border rounded p-3 mt-3">
        <div class="row">
          <p class="col-md-2">{{ $t('Provider') }}</p>
          <p class="col-md-10 fw-bold">
            {{ service.providerName }}
            {{ service.providerInn ? $t('INN') + ': ' + service.providerInn + ',' : '' }}
            {{ service.providerKpp ? $t('KPP') + ': ' + service.providerKpp + ',' : '' }}
            {{ service.providerAddress ? $t('address') + ': ' + service.providerAddress + ',' : '' }}
            {{ service.providerPhone ? $t('tel:') + ' ' + service.providerPhone : '' }}
          </p>
        </div>
        <div class="row">
          <p class="col-md-2">{{ $t('Client') }}</p>
          <p class="col-md-10 fw-bold">
            {{ service.сustomerName }}
            {{ service.customerInn ? $t('INN') + ': ' + service.customerInn + ',' : '' }}
            {{ service.сustomerAddress ? $t('address') + ': ' + service.сustomerAddress + ',' : '' }}
            {{ service.сustomerPhone ? $t('tel:') + ' ' + service.сustomerPhone : '' }}
          </p>
        </div>
        <div v-if="service.isBase != 1 && service.base" class="row">
          <p class="col-md-2">{{ $t('Base') }}</p>
          <p class="col-md-10 fw-bold">
            {{ service.base }}
          </p>
        </div>
        <div v-if="service.products" class="py-4">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">№</th>
                <th scope="col">{{ $t('Product or service') }}</th>
                <th scope="col">{{ $t('Qty') }}</th>
                <th scope="col">{{ $t('U') }}</th>
                <th scope="col">{{ $t('Price') }}</th>
                <th scope="col">{{ $t('Sum') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, i) in service.products" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ product.name }}</td>
                <td>{{ product.qty }}</td>
                <td>{{ product.unit }}</td>
                <td>{{ product.price }}</td>
                <td>{{ product.sum }}</td>
              </tr>
            </tbody>
          </table>
          <div class="row pt-3">
            <div class="col-md-8">
              {{ $t('Total items') }} {{ service.countItems }} {{ $t('for the amount') }}
              {{
                !service.isInvoiceVAT || (service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1')
                  ? Str.money(service.countSum)
                  : Str.money(service.countSumVat)
              }}
              ₽
            </div>
            <div class="col-md-4 fw-bold">
              <div class="row">
                <div class="col-6 text-end">
                  {{
                    service.priceIncludesVAT === '1' && service.isInvoiceVAT === '1'
                      ? $t('Total, incl. VAT')
                      : $t('Total')
                  }}:
                </div>
                <div class="col-6 text-end">{{ Str.money(service.countSum) }} ₽</div>
              </div>
              <div v-if="service.isInvoiceVAT" class="row">
                <div class="col-6 text-end">{{ $t('VAT') }}:</div>
                <div class="col-6 text-end">{{ Str.money(service.countVAT) }} ₽</div>
              </div>
            </div>
          </div>
          <div class="fs-5 fw-bold">
            {{ !service.textSum ? 'Ноль рублей 00 копеек' : service.textSum }}
          </div>
        </div>
        <div v-if="service.comment" class="d-flex">
          <div class="fw-bold">{{ $t('Comment') }}: </div>
          <div class="ms-2"> {{ service.comment }}</div>
        </div>
      </div>
    </div>

    <div class="mt-3 d-flex justify-content-end">
      <Button :label="$t('Prev')" @click="prevPage()" class="p-button-lg me-2 custom-p-button-outlined" icon="pi pi-angle-left" />
      <Button @click="download()" :label="$t('Create an account')" class="p-button-lg button-main" />
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Button from 'primevue/button';
import Form from '../../../../components/ui/Form.vue';
import FormField from '../../../../components/ui/FormField.vue';
import MultiField from '../../../../components/ui/MultiField.vue';
import Steps from '../../components/Steps.vue';
import Str from '../../../../utils/Str';
import '../../js/Roboto-Regular[1]-normal.js';

export default {
  components: {
    jsPDF,
    Button,
    Form,
    FormField,
    MultiField,
    Steps,
  },
  data() {
    return {
      service: this.$store.state.service,
      Str: Str,
    };
  },
  methods: {
    prevPage() {
      this.service.step = 3;
      this.$emit('step', 'payerDetails');
    },
    download() {
      var doc = new jsPDF('p', 'pt');
      doc.addImage('/img/invocelogo.png', 'PNG', 40, 15, 150, 20); // Добавляет лого компании
      doc.setFont('Roboto-Regular[1]');

      // основная таблица
      doc.autoTable({
        html: '#table-info',
        theme: 'grid',
        margin: { top: 70 },
        styles: {
          lineColor: '#000000',
          lineWidth: 1.5,
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
        },
      });

      // заголовок
      doc.autoTable({
        html: '#main-header',
        theme: 'plain',
        margin: { top: 50 },
        cellPadding: 0,
        styles: {
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
          fontSize: 18,
        },
      });

      // таблица поставщика
      doc.autoTable({
        html: '#table-provider',
        theme: 'plain',
        margin: { top: 40 },
        styles: {
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
        },
      });

      // таблица товаров или услуг
      doc.autoTable({
        html: '#table-product',
        theme: 'grid',
        horizontalPageBreak: true,
        margin: { top: 70 },
        tableLineColor: '#dd0f0f',
        styles: {
          lineColor: '#000000',
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
          fillColor: '#fff',
          textColor: '#000',
        },
      });

      // таблица с ценами (итого)
      doc.autoTable({
        html: '#table-total',
        theme: 'plain',
        margin: { top: 70 },
        styles: {
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
          fillColor: '#fff',
          textColor: '#000',
          styles: { halign: 'center' },
        },
      });

      // таблица с комментарием
      doc.autoTable({
        html: '#table-comment',
        theme: 'plain',
        margin: { top: 70 },
        styles: {
          font: 'Roboto-Regular[1]',
          fontStyle: 'normal',
          fillColor: '#fff',
          textColor: '#000',
        },
      });

      // футер
      doc.addImage('/img/invocelogo.png', 'PNG', 130, 800, 120, 16); // Добавляет лого компании
      doc.setFontSize(10);
      doc.textWithLink('Создано при помощи invoice.vionvi.ru', 270, 813, { url: 'https://vionvi.ru/invoice' });

      var tableInfo = document.getElementById('table-info');

      // doc.setLineWidth(1.5); // добавляем ширину линии
      // doc.setDrawColor(0, 0, 0);
      // doc.line(40, 30, 555, 30); // добавляем линию

      doc.save('invoice.pdf');
    },
  },
};
</script>
