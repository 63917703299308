<template>
  <Steps :step="3" />
  <div class="mt-5">
    <Form :actions="false" :service="service" @submit.prevent="onSubmit">
      <div>
        <h1 class="fs-3">{{ $t('Payer details') }}</h1>
        <div class="p-fluid mt-4 mb-3">
          <div class="p-field">
            <AutoComplete
              v-model="service.сustomerValue"
              :suggestions="filteredDadata"
              placeholder="ООО СИРИУС"
              @input="getDadata(service.сustomerValue)"
              @complete="searchDadata($event)"
              field="value"
              modelValue="сustomerName"
              :class="service.errors && service.errors.сustomerValue ? 'p-invalid is-invalid' : ''"
            >
              <template #item="slotProps">
                <div>{{ slotProps.item.value }}</div>
                <div class="text-muted fs-7">
                  {{ slotProps.item.data.address.value }}
                </div>
              </template>
            </AutoComplete>
            <div v-if="service.errors && service.errors.сustomerValue" class="invalid-feedback">
              {{ service.errors ? service.errors.сustomerValue : null }}
            </div>
          </div>
        </div>
        <!-- <FormField :service="service" field="сustomerName" :label="$t('Label')" /> -->
        <FormField :service="service" field="customerInn" :label="$t('INN')" />
      </div>
      <div class="mt-5">
        <h1 class="fs-3">{{ $t('Contact details') }}</h1>
        <FormField :service="service" field="сustomerAddress" :label="$t('Address')" />
        <FormField
          :service="service"
          field="сustomerPhone"
          :label="$t('Phone')"
          type="mask"
          mask="+7 (999) 999-99-99"
          placeholder="+7 (999) 123-45-67"
        />
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <Button :label="$t('Prev')" @click="prevPage()" class="p-button-lg me-2 custom-p-button-outlined" icon="pi pi-angle-left" />
        <Button type="submit" :label="$t('Save and Continue')" class="p-button-lg button-main" icon="pi pi-angle-right" icon-pos="right" />
      </div>
    </Form>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Form from '../../../../components/ui/Form.vue';
import FormField from '../../../../components/ui/FormField.vue';
import MultiField from '../../../../components/ui/MultiField.vue';
import Steps from '../../components/Steps.vue';
import axios from 'axios';

export default {
  components: {
    AutoComplete,
    Button,
    Form,
    FormField,
    MultiField,
    Steps
  },
  data() {
    return {
      service: this.$store.state.service,
      dadata: null,
      filteredDadata: null,
    };
  },
  methods: {
    prevPage() {
      this.service.step = 2;
      this.$emit('step', 'recipientDetails');
    },
    onSubmit() {
      this.service.validate();
      if (Object.keys(this.service.errors).length == 0) {
        this.service.step = 4;
        this.$emit('step', 'Confirmation');
      }
    },
    searchDadata() {
      setTimeout(() => {
        this.filteredDadata = this.dadata.suggestions.filter((data) => {
          return data;
        });
      }, 250);
    },
    async getDadata(data) {
      let url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
      var token = '4c5ff6f2a9bd28e43d1dc89e557b94ac23582551';
      var query = data;

      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        data: {
          query: query,
        },
      };
      await axios(options)
        .then((response) => response.data)
        .then((result) => {
          this.dadata = result;
        })
        .catch((error) => console.log('error', error));
    },
  },
};
</script>
